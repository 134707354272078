<template>
  <el-form
    :model="form"
    :rules="rules"
    status-icon
    ref="ruleForm"
    label-width="140px"
    class="company-ruleForm"
    :disabled="submitting"
    v-loading="loading"
  >
    <el-form-item label="被考核人" prop="examineeId">
      <el-select
        :disabled="!isEmprty(id)"
        v-model="form.examineeId"
        placeholder="请选择"
        clearable
        filterable
        class="base-select"
        @change="onExamineeChange"
      >
        <el-option
          v-for="item in examineOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="考核部门" prop="departmentId">
      <el-select
        v-model="form.departmentId"
        placeholder="请选择"
        clearable
        filterable
        class="base-select"
      >
        <el-option
          v-for="item in departmentOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="要求签约客户数" prop="requestSignCount">
      <el-input
        type="number"
        v-model="form.requestSignCount"
        placeholder="请输入"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item label="考核日期" prop="yearMonth">
      <el-date-picker
        :disabled="!isEmprty(id)"
        style="width: 100%"
        v-model="form.yearMonth"
        type="month"
        value-format="yyyy-MM"
        placeholder="选择考核日期"
        clearable
      >
      </el-date-picker>
    </el-form-item>

    <el-form-item style="text-align: right">
      <el-button @click="onCancel" :disabled="submitting" :loading="loading"
        >关 闭</el-button
      >
      <el-button
        v-if="isEmprty(id)"
        type="primary"
        :loading="submitting"
        @click="submitForm('ruleForm')"
        >提交创建</el-button
      >
      <el-button
        type="warning"
        :loading="submitting"
        @click="editForm('ruleForm')"
        v-if="!isEmprty(id)"
        >提交修改</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { PutKpiById, PostKpi, GetKpiById } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import formSeletion from "../mixins/formSeletion";
export default {
  props: {
    id: {
      type: [Number, String],
      default: "",
    },
  },
  mixins: [formSeletion],
  data() {
    return {
      loading: false,
      submitting: false,
      currentId: null,
      areaValue: [],
      form: {
        departmentId: null,
        examineeId: null,
        requestSignCount: null,
        yearMonth: "",
      },
      rules: {
        requestSignCount: [
          { required: true, message: "请输入要求签约客户数", trigger: "blur" },
        ],
        yearMonth: [
          {
            required: true,
            message: "请选择考核日期",
            trigger: ["blur", "change"],
          },
        ],
        departmentId: [
          {
            required: true,
            message: "请选择部门",
            trigger: ["blur", "change"],
          },
        ],
        examineeId: [
          {
            required: true,
            message: "请选择被考核人",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    id: {
      handler() {
        this.initFormOptions();
        if (!isEmprty(this.id)) {
          this.currentId = this.id;
          this.getFormData(this.id);
        } else {
          this.currentId = null;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    checkPhoneNumber(rule, value, callback) {
      // console.log('rule', rule)
      if (value && value !== "") {
        let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("电话号码格式不正确"));
        }
      } else {
        return callback();
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          this.onPostForm();
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    onPostForm() {
      PostKpi({
        ...this.form,
      })
        .then(() => {
          this.submitting = false;
          this.onEmitSuccess();
          this.inintForm();
          this.$message.success("创建成功");
        })
        .catch((err) => {
          this.submitting = false;
          ShowApiError("新增失败", err);
        });
    },

    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutKpiById(this.id, {
            departmentId: this.form.departmentId,
            requestSignCount: this.form.requestSignCount,
          })
            .then(() => {
              this.submitting = false;
              this.onEmitSuccess();
              this.inintForm();
              this.$message.success("修改成功");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData(id = "") {
      this.loading = true;
      GetKpiById(id)
        .then((res) => {
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              this.form[key] = res.data[key];
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },
    onExamineeChange(val) {
      if(val){
        let target = this.examineOptions.find(item => item.id == val);
         this.form.departmentId = target ? target.departmentId : null;
      }else{
        this.form.departmentId = null;
      }
    },
    onCancel() {
      this.inintForm();
      this.$emit("cancel");
    },
    onEmitSuccess() {
      this.$emit("success");
    },
    inintForm() {
      this.form = {
        departmentId: null,
        examineeId: null,
        requestSignCount: null,
        yearMonth: "",
      };
      this.$refs["ruleForm"].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.file-content-list {
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 20px;
}
</style>