<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <basic-table
      showSelection
      hiddenClear
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          class="mt-r"
          size="small"
          type="primary"
          @click="addRow"
          v-if="checkPermission(['CLIENT_ADMIN'])"
          >新增</el-button
        >
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date_time
            }}</span>
            <span v-else-if="column.prop === 'address.country'">
              {{
                isEmprty(scope.row.address) ? "/" : scope.row.address.country
              }}
            </span>
            <span v-else-if="column.prop === 'address.province'">
              {{
                isEmprty(scope.row.address) ? "/" : scope.row.address.province
              }}
            </span>
            <span v-else-if="column.prop === 'address.city'">
              {{ isEmprty(scope.row.address) ? "/" : scope.row.address.city }}
            </span>
            <span v-else-if="column.prop === 'address.region'">
              {{ isEmprty(scope.row.address) ? "" : scope.row.address.region }}
            </span>
            <span v-else-if="column.prop === 'address.street'">
              {{ isEmprty(scope.row.address) ? "/" : scope.row.address.street }}
            </span>
            <span v-else-if="column.prop === 'disabled'">
              <el-tag type="success" v-if="!scope.row.disabled">正常</el-tag>
              <el-tag type="danger" v-if="scope.row.disabled">失效</el-tag>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              :loading="scope.row.canUpdateChecking"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canUpdateChecking ? "权限检验中..." : "修改" }}
            </el-button>
            <el-button
              class="text-danger"
              :loading="scope.row.canDeleteChecking"
              @click.native.prevent="deleteRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.canDeleteChecking ? "权限检验中..." : "删除" }}
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          v-if="checkPermission(['CLIENT_ADMIN'])"
          type="danger"
          size="small"
          @click="onMultipleDelete"
          :disabled="multipleSelected.length <= 0"
          :loading="multipleDeletedeleteLoading"
          >{{ multipleDeletedeleteLoadingText }}
          {{ multipleSelected.length }}</el-button
        >
      </template>
    </basic-table>
    <el-dialog
      :title="isEmprty(currentId) ? '新增KPI' : '修改KPI'"
      :visible.sync="formDialog"
      append-to-body
      :close-on-click-modal="false"
      width="800px"
    >
      <Form :id="currentId" @cancel="onFormCancel" @success="onFormSuccess" />
    </el-dialog>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import Form from "./components/Form";
import { GetKpiList, GetUpdateCheck } from "./api";
import { ShowApiError } from "@/request/error";
import { getOrderType } from "@/utils/common";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import formSeletion from "./mixins/formSeletion";
import FilterSection from "./components/FilterSection";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
import control from "./mixins/control";
import { ReadLocalColumn } from "@/utils/table";
export default {
  mixins: [formSeletion,control],
  components: {
    BasicTable,
    Form,
    FilterSection,
    PageHeader,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      deleteLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "createdAt",
      orderDirection: "descending",
      currentId: "",
      multipleSelected: [],
      orderPropObj: {},
    };
  },
  created() {
    this.buildTableColumns();
    this.initFormOptions();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "id",
            label: "ID",
            showColumn: true,
            minWidth: "80px",
            align: "center",
            sortable: "custom",
          },
          {
            prop: "disabled",
            label: "启/禁状态",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "examineeName",
            label: "考核人",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "yearMonth",
            label: "考核日期",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "departmentName",
            label: "部门",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "requestSignCount",
            label: "要求签约客戶数",
            showColumn: true,
            minWidth: "150px",
            sortable: "custom",
          },
          {
            prop: "actualSignCount",
            label: "实际签约客戶数",
            showColumn: true,
            minWidth: "150px",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建日期",
            width: 200,
            align: "center",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: 100,
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns)
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return { ...this.tableFilters };
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    getTableData() {
      this.tableLoading = true;
      GetKpiList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              canDeleteChecking: false,
              canUpdateChecking: false,
              canUpdate: false,
              canDelete: false
            };
          });;
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    addRow() {
      this.formDialog = true;
    },
    editRow(row) {
      if (row.canUpdate) {
        this.currentId = row.id;
        this.formDialog = true;
        return false;
      }
      row.canUpdateChecking = true;
      GetUpdateCheck(row.id)
        .then((res) => {
          row.canUpdateChecking = false;
          row.canUpdate = res.data;
          this.currentId = row.id;
          this.formDialog = true;
        })
        .catch(() => {
          row.canUpdate = false;
          this.$message.warning("权限校验异常，请稍后再试");
        })
        .finally(() => {
          row.canUpdateChecking = false;
        });
    },
    
    onFormCancel() {
      this.currentId = "";
      this.formDialog = false;
    },
    onFormSuccess() {
      if (!isEmprty(this.currentId)) {
        this.formDialog = false;
      }
      this.currentId = "";
      this.onRefresh();
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>