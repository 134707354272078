import { GetUsersAll } from "@/views/users/api";
import { GetDepartmentsAll } from "@/views/departments/api";
import { ShowApiError } from "@/request/error";
export default {
    data() {
        return {
            departmentOptions: [],
            examineOptions: [],
            userOptions: []
        }
    },
    methods: {
        initFormOptions() {
            GetUsersAll()
                .then((res) => {
                    this.userOptions = res.data;
                    this.examineOptions = res.data;
                })
                .catch((err) => {
                    ShowApiError("获取考核人选单失败", err);
                });
            GetDepartmentsAll()
                .then((res) => {
                    this.departmentOptions = res.data;
                })
                .catch((err) => {
                    ShowApiError("获取部门选单失败", err);
                });
        }
    }
}